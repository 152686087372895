import { useState } from "react";
import { useAuth } from "./context/auth";
import axios from "axios";
import { auth } from "./constants/firebase";
import "./Withdrawal.css";

export const Withdrawal = () => {
  const user = useAuth();
  const [isSubmitting, setSubmitting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errors, setErrors] = useState([]);
  if (user === undefined) {
    return null;
  }
  if (user === null) {
    return null;
  }

  const handleSubmit = async () => {
    setErrors([]);

    if (!confirmDelete) {
      setErrors((prev) => [
        ...prev,
        { message: "アカウント削除を確認してください。" },
      ]);
      return;
    }
    setSubmitting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_WORKER_URL}/account/withdrawal`,
        {},
        {
          headers: {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
          },
        }
      );
      window.alert("アカウント削除しました。");
    } catch (e) {
      setErrors((prev) => [
        ...prev,
        { message: "アカウント削除に失敗しました。" },
      ]);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="account-deletion-container">
      <h2>アカウント削除</h2>
      <p className="warning">
        この操作を行うと、アカウントを復旧することはできません。
      </p>
      <div className="checkbox-group">
        <input
          type="checkbox"
          id="confirm-delete"
          checked={confirmDelete}
          onChange={(e) => setConfirmDelete(e.target.checked)}
        />
        <label htmlFor="confirm-delete">
          アカウントの完全な削除に同意します。
        </label>
      </div>
      <button
        onClick={handleSubmit}
        disabled={isSubmitting || !confirmDelete}
        className="delete-button"
      >
        Delete My Account
      </button>
      {errors.map((error, index) => (
        <p key={`error-${index}`} style={{ color: "red" }}>
          {error.message}
        </p>
      ))}
    </div>
  );
};

export default Withdrawal;
