import { useState } from "react";

import "./App.css";
import { auth } from "./constants/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    // Validation
    setErrors([]);
    if (email === "" || !email) {
      setErrors((prevErrors) => [
        ...prevErrors,
        { message: "メールアドレスが必要です。" },
      ]);
    }
    if (password === "" || !password) {
      setErrors((prevErrors) => [
        ...prevErrors,
        { message: "パスワードの入力が必要です。" },
      ]);
    }
    if (errors.length > 0) {
      return;
    }

    // Try submit
    setIsSubmitting(true);
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        console.error(error);
        setErrors((prevErrors) => [
          ...prevErrors,
          { message: "ログインに失敗しました。" },
        ]);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="loginContainer">
      <div className="loginBox">
        <h2 className="title">
          Hashigo
          <br />
          アカウント管理
        </h2>
        <div className="inputGroup">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input"
          />
        </div>
        <div className="inputGroup">
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Password"
            className="input"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="inputGroup">
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="button"
          >
            ログイン
          </button>
        </div>
        {errors.map((error, index) => (
          <p id="errorMessage" key={`error-${index}`} className="error">
            {error.message}
          </p>
        ))}
      </div>
    </div>
  );
}

export default App;
