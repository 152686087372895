import React from "react";
import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../constants/firebase";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
      console.log(firebaseUser);
      if (firebaseUser) {
        // ログイン完了したらWithdrawalに移動
        navigate("/withdrawal");
      } else if (firebaseUser === null) {
        // 未ログイン確定でルートに移動
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
