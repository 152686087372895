import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBoZeJ428yS-sRF288FBspdJRNqcXUY7J8",
  authDomain: "hashigo-firebase.firebaseapp.com",
  projectId: "hashigo-firebase",
  storageBucket: "hashigo-firebase.appspot.com",
  messagingSenderId: "1066016532684",
  appId: "1:1066016532684:web:d740d5254b909a804e7a69",
  measurementId: "G-ZKFJ7XWVHQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
